import { Button, Text, Title } from '@mantine/core';
import { Navbar } from '../../components/Navbar';
import { Projects } from '../../projects';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay'

import { Autoplay } from 'swiper/modules';
import { useEffect, useState } from 'react';

export const Home = () => {
    const [index, setIndex] = useState(0)
    const project = Projects[index]

    useEffect(() => {
        //preload background images
        Projects.forEach((project) => {
            const img = new Image();
            img.src = project.primaryLargeImage;
        });
        //preload card images
        Projects.forEach((project) => {
            const img = new Image();
            img.src = project.primaryCardImage;
        });
    }, [])

    return (
        <div className="home__background" style={{ backgroundImage: `url(${project.primaryLargeImage})` }}>

            <Navbar />

            <div className="home__container">

                <div className="home__title-container">
                    <div className="line"></div>
                    <Text className="home__subtitle">{project.subtitle}</Text>
                    {project.titleCard()}
                    <Text className="home__subtitle">{project.description}</Text>
                    {project.button()}
                </div>

                <div className="home__cards-container">

                    <Swiper
                        modules={[]}
                        spaceBetween={20}
                        slidesPerView={2}
                        onSlideChange={(swiper) => setIndex(swiper.activeIndex)}
                        onSwiper={(swiper) => console.log(swiper)}
                        autoplay
                    >

                        {Projects.map(project => {
                            return (
                                <SwiperSlide>
                                    {({ isActive }) => (
                                        <>
                                            { <div className={`home__card${isActive ? " active" : ""}`} style={{ backgroundImage: `url(${project.primaryCardImage})` }}>
                                                <div className="home__card-gradient" />
                                                {project.lottie}
                                                <div style={{flex: 1}} />
                                                <Title className="home__card-title" order={1}>{project.name}</Title>
                                                <Text className="home__card-description">{project.shortDescription}</Text>
                                            </div>}
                                        </>
                                    )}
                                </SwiperSlide>
                            )
                        })}

                        <SwiperSlide></SwiperSlide>
                    </Swiper>
                </div>

            </div>
        </div>
    )
}