import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./styles/main.scss";
import { Home } from "./pages/home/Home";
import { Contact } from "./pages/contact/Contact";
import { Project } from "./pages/project/Project";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login",
    element: <div>Login!</div>,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div >
  );
}

export default App;
