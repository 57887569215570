import { Text } from "@mantine/core"
import { Outlet, Link } from "react-router-dom";

export const Navbar = () => {
    return (
        <div className="navbar">

            <Link to="/">
                <img src="icon-planet.png" className="navbar__logo" />
            </Link>

            <div className="navbar__row">

                <Text className="navbar__link">
                    <Link to="/">HOME</Link>
                </Text>
                <Text className="navbar__link">
                    <Link to="/contact">CONTACT</Link>
                </Text>

                <img src="email.svg" className="navbar__email" />
            </div>
        </div>
    )
}