import { Text, Title } from '@mantine/core';
import { Navbar } from '../../components/Navbar';
import { Projects } from '../../projects';
import Lottie from 'lottie-react';
import email from "../../email.json"

export const Contact = () => {

    return (
        <div className="home__background" style={{ backgroundImage: `url(${Projects[0].primaryLargeImage})` }}>

            <Navbar />

            <div className="home__container">

                <div className="home__title-container">
                    {/* <div className="line"></div> */}
                    {/* <Text className="home__subtitle">subtitle</Text> */}
                    <Title order={1} className="home__title"><span style={{ color: "#0FDDF5" }}>Hey There...</span><br />Reach Out!</Title>
                    <Text className="home__subtitle">Have questions about our services / mobile apps? Or just want to get into contact with us? Feel free to contact us via email for a timely response!.</Text>
                </div>

                <div className="home__cards-container">
                    <div className='home__email'>
                        <a href="mailto:alan@saxaindustries.com">
                            <Title>alan@saxaindustries.com</Title>
                        </a>
                        <Lottie animationData={email} loop={true} className='home__email-animation' />
                    </div>
                </div>
            </div>
        </div>
    )
}