import { Button, Title } from "@mantine/core"
import Lottie from "lottie-react"
import { Link } from "react-router-dom"
import swipe from "./swipe.json"

interface Project {
    name: string
    subtitle: string
    description: any
    shortDescription: string
    primaryLargeImage: string
    primaryCardImage: string
    hidden?: boolean
    titleCard: any
    button: any
    lottie?: any
}

export const Projects: Project[] = [
    {
        name: "SWIPE RIGHT",
        shortDescription: "to see our projects",
        subtitle: "Welcome to..",
        description: `A tech company. We specialize in mobile / fullstack software development. Our goal is to make great lifestyle + mental health apps available to all for cheap!`,
        primaryLargeImage: "outer-space-background.png",
        primaryCardImage: "home.png",
        hidden: true,
        titleCard: () => <Title order={1} className="home__title"><span style={{ color: "#0FDDF5" }}>Saxa</span> Industries</Title>,
        button: () => {},
        lottie: <Lottie animationData={swipe} loop={true} style={{marginTop: 20, marginRight: 50}} width={220}/>
    },
    {
        name: "Saxa Meditate",
        shortDescription: "Mobile App",
        subtitle: "React Native | Nest JS",
        description: "A minimalist meditation companion to help you on your journey to enlightenment.",
        primaryCardImage: "saxa-meditate-card.png",
        primaryLargeImage: "saxa-meditate-background.png",
        titleCard: () => <Title order={1} className="home__title"><span style={{ color: "#E582DE" }}>Saxa</span> Meditate</Title>,
        button: () => <a href="https://meditatematey.com/" target="_blank">
            <Button fullWidth={false} size="xl" radius="xl" color="#E582DE">Go to Website</Button>
        </a>
    },
    {
        name: "Saxa Register",
        shortDescription: "Tablet App",
        subtitle: "React Native | NestJS",
        description: "An All-in-one cash register / pos system. Made for simplicity and ease of use.",
        primaryCardImage: "saxa-register-card.png",
        primaryLargeImage: "saxa-register-background.png",
        titleCard: () => <Title order={1} className="home__title">Saxa <span style={{ color: "#0FCC93" }}>Register</span></Title>,
        button: () => {}
    },
    // {
    //     name: "Saxa Routine",
    //     shortDescription: "See what we are up to",
    //     subtitle: "Welcome to..",
    //     description: "description 2",
    //     primaryLargeImage: "outer-space-background.png",
    //     primaryCardImage: "outer-space-background.png",
    //     titleCard: () => <Title order={1} className="home__title"><span style={{ color: "#0FDDF5" }}>Upcoming</span> Projects</Title>,
    //     button: () => <Link to="/project/3">
    //         <Button fullWidth={false} size="xl" radius="xl" color="#0060FF">See More</Button>
    //     </Link>
    // }
]